exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-journey-academic-life-tsx": () => import("./../../../src/pages/journey/academic_life.tsx" /* webpackChunkName: "component---src-pages-journey-academic-life-tsx" */),
  "component---src-pages-journey-citymob-tsx": () => import("./../../../src/pages/journey/citymob.tsx" /* webpackChunkName: "component---src-pages-journey-citymob-tsx" */),
  "component---src-pages-journey-hello-world-tsx": () => import("./../../../src/pages/journey/hello_world.tsx" /* webpackChunkName: "component---src-pages-journey-hello-world-tsx" */),
  "component---src-pages-journey-index-tsx": () => import("./../../../src/pages/journey/index.tsx" /* webpackChunkName: "component---src-pages-journey-index-tsx" */),
  "component---src-pages-journey-masterstart-tsx": () => import("./../../../src/pages/journey/masterstart.tsx" /* webpackChunkName: "component---src-pages-journey-masterstart-tsx" */),
  "component---src-pages-journey-superbalist-tsx": () => import("./../../../src/pages/journey/superbalist.tsx" /* webpackChunkName: "component---src-pages-journey-superbalist-tsx" */),
  "component---src-pages-journey-yoco-tsx": () => import("./../../../src/pages/journey/yoco.tsx" /* webpackChunkName: "component---src-pages-journey-yoco-tsx" */),
  "component---src-pages-projects-index-tsx": () => import("./../../../src/pages/projects/index.tsx" /* webpackChunkName: "component---src-pages-projects-index-tsx" */),
  "component---src-pages-projects-open-source-tsx": () => import("./../../../src/pages/projects/open-source.tsx" /* webpackChunkName: "component---src-pages-projects-open-source-tsx" */),
  "component---src-pages-terms-and-conditions-tsx": () => import("./../../../src/pages/terms_and_conditions.tsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-tsx" */)
}

